import React from 'react';
import './Ceramics.css'

const Ceramics = () => {
  return (
    <div className='ceramics'>
      <h1>Welcome to the Ceramics Page</h1>
      <p>This page is all about ceramics.</p>
    </div>
  );
};

export default Ceramics;
